html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

/* https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing */
body > iframe {
  pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* SCROLLER */

::-webkit-scrollbar {
  /* border-radius: 10px; */
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: darkgray;
}
