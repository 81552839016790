.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: auto;
  height: auto;
  margin-top: -12px;
  margin-left: -80px;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}
.lds-ellipsis div {
  position: absolute;
  margin-top: "12px";
  margin-bottom: "12px";
  left: "50%";
  top: "7px";
  width: 13px;
  height: 13px;
  border-radius: 50%;
  /* background: black;  */
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
